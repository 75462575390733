import React, {useEffect, useRef, useState} from "react";
import {collection, doc, query, getDoc, deleteDoc, where, getDocs, setDoc} from "firebase/firestore";
import {db} from "../../index";
import {useNavigate, useParams} from "react-router-dom";
import globalMasehtot from "../../masehtot.json";
import SiumProgress from "./siumProgress";
import UserProgress from "../../userStatus/userProgress";
import MenuStatus from "./menuStatus";
import Spinner from "../../spinner";
import ConfettiComponent from "../../userStatus/confetti";

const DisplaySium = () => {

    const params = useParams();
    const [sium, setSium] = useState([]);
    const [takenList, setTakenList] = React.useState();
    const [learningProgress, setLearningProgress] = useState(0);
    const listRef = useRef();

    function getUserMasehtotStatus(user) {
        let counterPages = 0;
        let displayMasehtotSelection = [];
        const chaptersNames = globalMasehtot.allChapters;
        for (const m_i in user.masehtot) {
            let wholeMasehetSelected = false;
            const masehet = user.masehtot[m_i];
            let chaptersSelection = [];
            for (const c_i in masehet.chapters) {
                const chapter = masehet.chapters[c_i];
                let wholeChapterWasFinished = true;
                for (const p_i in chapter.finish) {
                    if (chapter.finish[p_i]) {
                        counterPages++;
                    } else {
                        wholeChapterWasFinished = false;
                    }
                }
                chaptersSelection.push({name: chaptersNames[parseInt(chapter.chapterNum) - 1], finish: wholeChapterWasFinished, chapterNum: chapter.chapterNum})
            }
            const globalMasehet = globalMasehtot.allMasehtot[masehet.masehetId];
            if (chaptersSelection.length === globalMasehet.totalChapters) {
                wholeMasehetSelected = true;
            }
            displayMasehtotSelection.push({name:globalMasehtot.allMasehtot[masehet.masehetId].name,
                chapters: chaptersSelection.sort((a,b) => (a.chapterNum > b.chapterNum) ? 1 : ((b.chapterNum > a.chapterNum) ? -1 : 0)),
                wholeMasehetSelected: wholeMasehetSelected, masehetId: masehet.masehetId});
        }
        return {counterPages: counterPages, displayMasehtotSelection: displayMasehtotSelection};
    }

    function shareUserPage() {
        if (navigator.share) {
            navigator.share({
                title: 'סיום הש"ס של ' + sium.kehilaName,
                text: 'תצוגת סיום הש"ס של ' + sium.kehilaName,
                url: '/displaySium/' + params.siumId,
            });
        }
    }

    function getTakenMasehtot(usersList) {
        if (!sium || !sium.masehtotSelection) return;
        const masehtotKeysList = Object.keys(globalMasehtot.allMasehtot);

        const chunkArray = (array, size) => {
            const result = [];
            for (let i = 0; i < array.length; i += size) {
                result.push(array.slice(i, i + size));
            }
            return result;
        };

        const masehtotList = masehtotKeysList.map((key, index) => {
            const currentMasehet = globalMasehtot.allMasehtot[key];
            const filteredUsers = usersList.filter(user => user.masehtot.some(masehet => masehet.masehetId === key));
            const userChunks = chunkArray(filteredUsers, 4);

            return userChunks.map((chunk, chunkIndex) => (
                <div className={`carousel-item ${index === 0 && chunkIndex === 0 ? 'active' : ''}`} key={`${key}-takenMasehet-${chunkIndex}`}>
                    <h2 className="">
                        {currentMasehet.name}
                    </h2>
                    <div id={`collapseTaken${key}-${chunkIndex}`} className="">
                        <div className="accordion-body">
                            <div className="lomdim-list-container card" id={`${key}Lomdim-${chunkIndex}`}>
                                <span className="card-header bold">"ואלה יעמדו על הברכה"</span>
                                <ul className="lomdim-list list-group list-group-flush">
                                    {chunk.map(u => (
                                        <li className="list-group-item" key={u.name}>{u.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ));
        }).flat();

        return masehtotList;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            const docRef = doc(db, "siumim", params.siumId);
            getDoc(docRef).then(sium => {
                if (sium) {
                    setSium(sium.data());
                }
            });
        }, 2000);
    },[params.siumId]);

    useEffect(() => {
        if (sium.masehtotSelection) {
            let usersList = [];
            let finishedPagesCounter = 0;
            const q = query(collection(db, "users"), where("siumId", "==", params.siumId));
            getDocs(q).then(users => {
                users.forEach((user) => {
                    const userObj = user.data();
                    const userMasehtotStatus = getUserMasehtotStatus(userObj);
                    const finishPagesNum = userMasehtotStatus.counterPages;
                    finishedPagesCounter += finishPagesNum;
                    usersList.push({
                        name: userObj.fullName,
                        masehtot: userObj.masehtot
                    });
                });
                setLearningProgress(Math.floor((finishedPagesCounter / 5614) * 100));
                setTakenList(getTakenMasehtot(usersList));
            });
        }
    }, [sium]);

    useEffect(() => {
        if (typeof window !== "undefined" && window.bootstrap && takenList && takenList.length > 0) {
            const carouselElement = document.getElementById("carouselSiumSlides");
            if (carouselElement) {
                new window.bootstrap.Carousel(carouselElement, {
                    interval: 4000,
                    ride: 'carousel'
                });
            }
        }
    }, [takenList]);

    useEffect(() => {
        const listElement = listRef.current;
        if (listElement) {
            const observer = new ResizeObserver(() => {
                if (listElement.scrollHeight > listElement.clientHeight) {
                    listElement.classList.add("auto-scroll");
                } else {
                    listElement.classList.remove("auto-scroll");
                }
            });

            observer.observe(listElement);
            return () => observer.disconnect();
        }
    }, [takenList]);

    if (sium && sium.kehilaName && globalMasehtot.allMasehtot && takenList) {
        return(
            <div className="sium-main">
                <div className="sium-main-container">
                    <div className="sium-main-header">
                        <div className="basad">
                            <span>בס"ד</span>
                            <br/>
                            <span>להצלחת עם ישראל</span>
                        </div>
                    </div>
                    <div className="sium-logo">
                        <a className="sium-logo-img" href={"/"} aria-label="Sium shas logo"/>
                    </div>
                    <div className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 sium-titles">
                        {sium.nishmat && <span>הלימוד {sium.nishmat}</span>}
                        <h2>סיום הש"ס של {sium.kehilaName}</h2>
                    </div>
                    <div id="displayMasehtot" className="sium-status-options-container">
                        <div className="" id="takenMasehtot">
                            <div className="card card-body">
                                <SiumProgress siumPerc={learningProgress} message="מסכתות שנלמדו"/>
                                <div id="carouselSiumSlides" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        {takenList}
                                    </div>
                                </div>
                                <button className="btn sium-button share-display-sium" onClick={() => shareUserPage()}>שיתוף תצוגה</button>
                            </div>
                        </div>
                    </div>
                    <div className="sium-bottom-page">
                        <span> © כל הזכויות שמורות לאתר סיום הש"ס</span>
                    </div>
                </div>
                {learningProgress === 100 && <ConfettiComponent></ConfettiComponent>}
            </div>
        )
    } else {
        return (<Spinner/>);
    }

}

export default DisplaySium;
